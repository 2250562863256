<template>
  <div class="pageCenter">
    <el-backtop></el-backtop>
    <div class="main">
      <div class="heardePicture_menu">
        <ul>
          <li @click="selectPage_1">
            <span v-if="chooseItem"
              ><img src="@/assets/image/产品服务/大数据云平台-选中.png" alt=""
            /></span>
            <span v-else
              ><img src="@/assets/image/产品服务/大数据云平台.png" alt=""
            /></span>
          </li>
          <li @click="selectPage_2">
            <span v-if="chooseItem"
              ><img src="@/assets/image/产品服务/安全平台-选中.png" alt=""
            /></span>
            <span v-else
              ><img src="@/assets/image/产品服务/安全平台.png" alt=""
            /></span>
          </li>
          <li @click="selectPage_3">
            <span v-if="chooseItem"
              ><img src="@/assets/image/产品服务/大数据-选中.png" alt=""
            /></span>
            <span v-else
              ><img src="@/assets/image/产品服务/大数据-选中.png" alt=""
            /></span>
          </li>
          <li @click="selectPage_4">
            <span v-if="chooseItem"
              ><img src="@/assets/image/产品服务/APP出行平台-选中.png" alt=""
            /></span>
            <span v-else
              ><img src="@/assets/image/产品服务/APP出行平台.png" alt=""
            /></span>
          </li>
          <li @click="selectPage_5">
            <span v-if="chooseItem"
              ><img src="@/assets/image/产品服务/充电桩-选中.png" alt=""
            /></span>
            <span v-else
              ><img src="@/assets/image/产品服务/充电桩.png" alt=""
            /></span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  methods: {
    selectPage_1() {
      this.$router.push('/productService')
      window.scrollTo(0, 0)
    },
    selectPage_2() {
      this.$router.push('/securityPlatform')
      window.scrollTo(0, 0)
    },
    selectPage_3() {
      this.$router.push('/monitoringPlatform')
      window.scrollTo(0, 0)
    },
    selectPage_4() {
      this.$router.push('/travelApp')
      window.scrollTo(0, 0)
    },
    selectPage_5() {
      this.$router.push('/chargingPile')
      window.scrollTo(0, 0)
    }
  }
}
</script>
<style lang="less" scoped>
.main {
  width: 100%;
  height: 6400px;
  background: url('../../assets/image/产品服务/大数据/大数据.png') no-repeat;
}
.heardePicture_menu {
  position: fixed;
  z-index: 999;
  top: 222px;
  right: 100px;
  ul {
    width: 124px;
    li {
      width: 124px;
      text-align: center;
    }
  }
}
</style>
